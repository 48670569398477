body {
  overflow-x: scroll;
}

body,
body > #root,
#app {
  min-width: 950px;
}

.subform-group {
  float: left;
}

div.checkboxes {
  display: flex;
  flex-wrap: wrap;
}

.checkboxes > div.checkbox {
  width: 30rem;
  margin: 0;
}

.checkboxes > div.checkbox > label {
  padding-left: 6rem;
  margin: 1rem 0 0;
}

.checkboxes > div.checkbox > label > span > span {
  display: inline-block;
  vertical-align: middle;
}
.checkboxes > div.checkbox > label input[type='checkbox'] {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0 6rem 0 0;
  margin: -0.6rem;
  top: 1.9rem;
  left: 1.5rem;
  -webkit-appearance: checkbox;
}

span.required {
  display: none;
}

.ReactTable .-pagination .-btn {
  margin-top: 0;
}

ul.error-detail.bs-callout.bs-callout-info {
  color: #a94442;
}

div.summary-display table {
  width: 100%;
  max-width: 80rem;
}
div.summary-display th,
td {
  border: none;
  margin: 3px;
}

.scrollbox {
  overflow: auto;

  background-image: 
    /* Shadows */ linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    /* Shadow covers */
      linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 15px 100%, 30px 100%;

  background-attachment: local, local, scroll, scroll;
}

.column-container input,
.column-container textarea,
.column-container select {
  max-width: none;
}

.App div.summary-display table {
  max-width: none;
}

/* allows checkboxes and radio buttons work */
.input-style-override input {
  -webkit-appearance: auto !important;
  -moz-appearance: auto !important;
  appearance: auto !important;
  width: 2.5rem !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  left: 0 !important;
  height: 2.5rem !important;
}

.input-style-override label {
  padding-left: 4.3rem;
}

.overflow-auto div {
  overflow: auto;
}

.overflow-hidden div {
  overflow: hidden;
}

.small-description p {
  font-size: 1.3rem;
  margin-top: 2px !important;
}

.title-only-field div {
  padding-bottom: 0 !important;
}

.hide-label > label {
  font-size: 0rem;
}
